import JSBI from 'jsbi'

export const INIT_CODE_HASH = '0xf17d1f543d99f357c85ba8a119879748913969c4a10701864f1ac4f52440ee04'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
